import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n.language || "ge"
  );

  const changeLanguage = (selectedValue) => {
    i18n.changeLanguage(selectedValue);
    setSelectedLanguage(selectedValue);
  };

  return (
    <select
      className="language-switcher"
      value={selectedLanguage}
      onChange={(e) => {
        changeLanguage(e.target.value);
      }}
    >
      <option
        className={`lang geo ${selectedLanguage === "ge" ? "selected" : ""}`}
      >
        Georgian
      </option>
      <option
        className={`lang eng  ${selectedLanguage === "en" ? "selected" : ""}`}
      >
        English
      </option>

      <option
        className={`lang rus ${selectedLanguage === "ru" ? "selected" : ""}`}
      >
        Russian
      </option>
      <option
        className={`lang tur ${selectedLanguage === "tur" ? "selected" : ""}`}
      >
        Turkish
      </option>
    </select>
  );
};

export default LanguageSwitcher;
