import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTelegram,
  faWhatsapp,
  faViber,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/logo.svg";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="company-ft-logo">
        <img className="logo-bottom" src={logo} alt="company logo" />{" "}
      </div>
      <div className="icons">
        <div className="text-icon">
          <div className="icon-number">
            <a
              href="https://api.whatsapp.com/send?phone=+995557396727"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                className="social-icon"
                icon={faWhatsapp}
                color="#25d366"
              />
            </a>
            <a href="tel:+995951950663"> +995 557396727</a>
          </div>
          <div className="icon-number">
            <FontAwesomeIcon
              className="social-icon"
              icon={faViber}
              color="#59267c"
            />
            <span className="number"> +995 557396727</span>
          </div>
          <div className="icon-number">
            <FontAwesomeIcon
              className="social-icon"
              icon={faPhone}
              color=" #0088cc"
            />
            <span className="number"> +995 557396727</span>
          </div>
        </div>
        <div className="nontext-icon">
          <FontAwesomeIcon
            className="social-icon"
            icon={faFacebook}
            color="#1877f2"
          />
          <a
            href="https://t.me/+BFM2rxPtjTs0Njcy"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              className="social-icon"
              icon={faTelegram}
              color=" #0088cc"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
