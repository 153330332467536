import "./contact.scss";
import Header from "../header/Header";
import Footer from "../footer/footer";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTelegram,
  faWhatsapp,
  faViber,
} from "@fortawesome/free-brands-svg-icons";
const Contact = () => {
  return (
    <>
      <Header />
      <section className="contact-container">
        <h1>contact us with every source</h1>
        <address>address: Tbilisi, Pekini Street 52</address>
        <div className="social-networks">
          <div className="social-network">
            <p>Facebook </p>
            <FontAwesomeIcon
              icon={faFacebook}
              color="#1877f2"
              className="social-icon"
            />
            <span className="click">&#8601; Click me</span>
          </div>
          <div className="social-network">
            <p> Telegram Channel</p>
            <a
              href="https://t.me/+BFM2rxPtjTs0Njcy"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                className="social-icon"
                icon={faTelegram}
                color=" #0088cc"
              />
            </a>
            <span className="click">&#8601; Click me</span>
          </div>
          <div className="social-network">
            <p>Whatsapp</p>
            <FontAwesomeIcon
              icon={faWhatsapp}
              color="#25d366"
              className="social-icon"
            />
            <span className="click">&#8601; Click me</span>
          </div>
          <div className="social-network">
            <p>Viber</p>
            <FontAwesomeIcon
              icon={faViber}
              color="#59267c"
              className="social-icon"
            />
            <span className="click">&#8601; Click me</span>
          </div>
          <div className="social-network">
            <p>Phone</p>
            <FontAwesomeIcon
              icon={faPhone}
              color=" #0088cc"
              className="social-icon"
            />
            <span className="click">&#8601; Click me</span>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
